@font-face {
  font-family: "Core Sans";
  font-weight: 400;
  src: url("/public/assets/fonts/core-sans/Fontspring-coresansar35.otf")
    format("opentype");
}
@font-face {
  font-family: "Core Sans";
  font-weight: 500;
  src: url("/public/assets/fonts/core-sans/Fontspring-coresansar45.otf")
    format("opentype");
}
@font-face {
  font-family: "Core Sans";
  font-weight: 600;
  src: url("/public/assets/fonts/core-sans/Fontspring-coresansar55.otf")
    format("opentype");
}
@font-face {
  font-family: "Core Sans";
  font-weight: 700;
  src: url("/public/assets/fonts/core-sans/Fontspring-coresansar65.otf")
    format("opentype");
}

/* Global general styles */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 62.5%;
  font-family: "Poppins", "Roboto", "Core Sans", Lato, "Be Vietnam", "Noto Sans",
    sans-serif;
  color: #1d1d1d;
}

body {
  overflow-x: hidden;
}

body::backdrop {
  background-color: rgba(255, 255, 255, 0);
}

/* Component specific styles */
button {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

input {
  font-family: "Core Sans", Lato, "Be Vietnam", "Noto Sans", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media only screen and (max-width: 48.75em) {
  html,
  body {
    font-size: 57.5%;
  }
}

@media only screen and (max-width: 37.5em) {
  html,
  body {
    font-size: 55%;
  }
}
